<template>
  <header class="mb-4 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-between gap-3">
        <div class="w-full md:max-w-sm grid grid-cols-1 lg:grid-cols-2 gap-4">
            <a-button type="primary" shape="round" :block="true" @click="$router.push('./roles/add')">
                <span class="flex items-start justify-start font-bold">
                    <a-icon type="plus" :style="{ fontSize: '12px'}" class="font-normal mr-2" />
                    <b>NUEVO ROL</b>
                </span>
            </a-button>
            <div v-if="loading" class="flex justify-center items-center">
                 <a-spin>
                     <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
                </a-spin>
            </div>
        </div>
        <div class="w-full sm:max-w-sm">
            <label for="table-search" class="sr-only">Search</label>
            <div class="relative">
                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                </div>
                <a-input-search placeholder="Buscar un rol" class="rounded-full" style="width: 100%;" @search="onSearch" v-model="myTerm"/>
            </div>
        </div>
  </header>
</template>

<script>
export default {
    props: {
        showButton: Boolean,
        loading: Boolean,
        search: String
    },
    methods: {
        onSearch(value){
            this.$notification.open({
                title: 'Búsqueda',
                message: 'Buscando...'
            });
            this.$emit('search-term', value);
        },
    },
    data() {
        return {
            myTerm: null
        }
    },
    mounted() {
        this.myTerm = this.$props.search;
    }
}
</script>

<style>

</style>